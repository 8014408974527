import React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/SEO"
import Logo from "../images/pp-logo.svg"


const IndexPage = () => (
  <>
  <SEO title="Become better" />
  {/* <Layout> */}
      {/* Content goes here */}
  {/* </Layout> */}

  <main className="h-screen">
    <section className="max-w-screen-lg mx-auto h-full  flex" >
        <div className="self-center text-center w-full">
          <img src={Logo} alt="Pragmatic Philosophy Logo" className="mx-auto w-56"/>
          <h1 className="font-thin">Pragmatic Philosophy</h1>
          <p className="font-header">Coming soon</p>
        </div>
    </section>
  </main>
  </>
)

export default IndexPage
